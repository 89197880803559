import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Header from './components/layout/header';
import Sidebar from './components/layout/sideBar';
import ContentContainer from './components/layout/contentContainer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {NotificationContainer} from 'react-notifications';

import './App.css';
import axios from 'axios';
import {setUser } from './ducks'
import Routes from './_routes.jsx'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0277bd"
    },
    secondary: {
      main: '#f44336',
    },
  }
});

const actions = {
  setUser
}

class App extends Component {
  state = {
    data: null,
    drawerOpen: false,
    loading: true
  };

  getUser = () => {
    let setUser = this.props.setUser;
    let that = this;
    axios({
      method: 'get',
      url: '/api/current-user',
    }).then(function (response) {
      console.log("get user:", response);
      if (response.data.user){

        let payload = {
          user : response.data.user,
          is_masquerade : response.data.is_masquerade,
          admin_id : response.data.admin_id
        }

        setUser(payload);
        that.setState({loading: false});
      }
      else {
        console.log("not logged in");
        that.setState({loading: false});
      }
    });
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleToggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  componentDidMount() {
      this.getUser();
  }

  render() {
    if (this.state.loading)
      return null;

    return (
        <MuiThemeProvider theme={theme}>
        <div className="App">
          <CssBaseline />
          <Header drawerOpen={this.state.drawerOpen} handleToggleDrawer={this.handleToggleDrawer}  />
          <Sidebar drawerOpen={this.state.drawerOpen} handleToggleDrawer={this.handleToggleDrawer} />
          <ContentContainer drawerOpen={this.state.drawerOpen} Content={<Routes />} />
        </div>
        <NotificationContainer/>
        </MuiThemeProvider>
    );
  }
}

const ConnectedApp = connect(null, actions)(App);
const AppWithRouter = withRouter(ConnectedApp);
export default AppWithRouter;
