import React from "react";

export default class Home extends React.Component {

	render() {
		return (
      <>
          Welcome to home
      </>
		)
	}
}
