import React from "react";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth-180,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth+10,
  },
	toolbarSpacer: {
		minHeight: "92px"
	}
});

class ContentContainer extends React.Component {

	render() {
		const { classes } = this.props;
		return (
			<main
					className={classNames(classes.content, {
						[classes.contentShift]: this.props.drawerOpen,
					})}
				>
				<div className={classes.toolbarSpacer} />
				{this.props.Content}
			</main>
		)
	}
}

export default withStyles(styles, { withTheme: true })(ContentContainer);
