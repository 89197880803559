import React from "react";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import axios from 'axios'
import { connect } from 'react-redux';
import {NotificationManager} from 'react-notifications';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  input: {
    display: "block"
  }
});

class Password extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user._id,
      password: "",
      new_password: "",
      new_password_match: ""
    };

  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  componentDidMount() {
  }

  changePassword = (e) => {
    e.preventDefault();
    let that = this;
    if (this.state.new_password !== this.state.new_password_match)
      NotificationManager.error("Passwords Don't Match", 'Try Again', 5000);
    else {
      axios({
        method: 'post',
        url: '/auth/changePassword',
        data: {
          password: this.state.password,
          new_password: this.state.new_password
        }
      }).then(function (response) {
        console.log("response:", response);
        if (response.data.message === "success"){
          NotificationManager.success("Passwords Successfully Changed", 'Thanks!', 5000);
          that.props.history.push("/default");
        }
        else {
          NotificationManager.error("Wrong password entered", 'Password Change Error', 5000);
        }
      });
    }
  };

	render() {
    const { list } = this.state;
    const { classes } = this.props;
		return (
      <span>
  			<h1 className={classes.h1}>Change Password</h1>
          <form onSubmit={this.changePassword}>
            <TextField
              className="inputs"
              label="Current Password"
              placeholder="Current Password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
          <br />
            <TextField
              className="inputs"
              label="New Password"
              placeholder="New Password"
              type="password"
              name="new_password"
              value={this.state.new_password}
              onChange={this.handleChange}
            />
          <br />
            <TextField
              className="inputs"
              label="Match Password"
              placeholder="Match Password"
              type="password"
              name="new_password_match"
              value={this.state.new_password_match}
              onChange={this.handleChange}
            />
          <br />
            <br />
            <Button type="submit" variant="contained" color="primary" className="btn_action">
              Change Password
            </Button>
          </form>
      </span>
		)
	}
}

const PasswordWithStyles = withStyles(styles, { withTheme: true })(Password);
export default connect(mapStateToProps)(PasswordWithStyles);
