import React from "react";

export default class Logo extends React.Component {

	render() {
		return (
      < >
				{/*<img src="/images/phly.jpg" alt="logo" height="85" />*/}
				logo
			</>
		)
	}
}
