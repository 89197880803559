import React from "react";
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import AddCircle from '@material-ui/icons/AddCircle';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};


class WeeklyHours extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      items: []
    }
  }

  componentDidMount() {
    //this.getEvents();
    //this.nav(moment().startOf('month').startOf('week'), moment().endOf('month').endOf('week'));
    let today = moment();
    let startday = moment().startOf("year").startOf("week");
    let endday = moment().endOf("year").endOf("week")
    // while (startday < today) {
    //     //this.nav(startday, endday);
    //     startday.add(7, "days");
    //     endday.add(7, "days")
    // }
    this.nav(startday, endday);
  }
  
  
  printRow = (o, weekCount) => {
    let keys = Object.keys(o);
    //console.log("printing row", o)
    return (
      <div key={"balls"+weekCount}>
      <h3>{o.start} - {o.end}</h3>
        {
         keys.sort().map(k => {
          if (k !== "start" && k !== "end")
          return <div key={"key"+weekCount+k}>{k} : {o[k]}</div>
          })
        }
      </div>
    )
  }

  nav = (start, end) => {
  
    this.setState({dummyStart: start, dummyEnd: end})
    let url = "/api/getMonthEvents";
    let that = this;
    let mongostart = start.toDate();
    let mongoend = end.toDate();
    let tmp;
    let hour;
    let length;
    let count = 0;
    let startTime;
    let endTime;
    let s;
    let e;
    let obj = {};
    axios({
      method: 'post',
      url: url,
      data: {start: mongostart, end: mongoend, unpublished: this.props.user.unpublished}
    }).then((response) => {
      console.log("new weekly events:", response);
      _.each(response.data, (v) => {
        v.start = moment(v.start).toDate();
        v.end = moment(v.end).toDate();
      });
     _.each(response.data, (v) => {
       tmp = v.description.split("-");
       _.each(tmp, (t) => {
         if (t.charAt(5) === "p"){
          hour = t.split(":");
          if (hour[0] === "12")
          t = (parseInt(hour[0]))+":"+hour[1].substring(0,2);
          else
          t = (parseInt(hour[0])+12)+":"+hour[1].substring(0,2);
        }
        else { //is am
          t = t.substring(0,5)
        }
        if (count === 0){
          startTime = t;
          count ++ 
        } else {
          endTime = t;
          count = 0;
        }
      
        //console.log("these are new timestamps", t);
       })
       v.startTime = startTime;
       v.endTime = endTime;
        
     })
     
     //console.log("this should have the extra data:", response.data)
     _.each(response.data, (v) => {
       s = "July 21 1982 "+v.startTime;
       if (parseInt(v.startTime.substring(0,2)) > parseInt(v.endTime.substring(0,2))) { //shift is overnight
         e = "July 22 1982 "+v.endTime;
       } else {
         e = "July 21 1982 "+v.endTime;
       }
       
       length = moment(e).diff(moment(s), "hours");
       v.length = length;
       
       //console.log("this is the hourse worked each shift", v.title, length)
       
      //  if (obj[v.title])
      //   obj[v.title] += length 
      // else {
      //     obj[v.title] = length
      // }
      
      //this.props.append(obj);
      
       
     })
     //console.log("weekly hour counts", obj)
     
      that.setState({
        events: response.data
      });
    });
  }

	render() {
    let today = moment().endOf("year");
    let startday = moment().startOf("year").startOf("week");
    let endday = moment().startOf("year").endOf("week");
    let weekCount = 0;
    let tmpobj = {};
    let objarry = [];
     while (startday < today) {
    //     //this.nav(startday, endday);
    //     startday.add(7, "days");
    //     endday.add(7, "days")
    // }
    //while (startday < today) {
    tmpobj.start = startday.format("MM/DD/YYYY");
    tmpobj.end = endday.format("MM/DD/YYYY");
    
    _.each(this.state.events, (e) => {
      if (moment(e.start).isBetween(startday, endday)){ // check if I'm in the right week
         if (tmpobj[e.title])
          tmpobj[e.title] += e.length 
        else {
            tmpobj[e.title] = e.length
        }
      }
      
      //console.log("check", e.start, moment(e.start), startday, endday, moment(e.start).isBetween(startday, endday))
      
    })
    
    objarry.push(tmpobj);
    tmpobj = new Object();
    startday.add(7, "days");
    endday.add(7, "days")
    }
    
    //console.log("now I have a arry", objarry);
    
    const { items } = this.state;
    const { classes } = this.props;
		return (
      <div>
      <h1>
        Weekly Hours
      </h1>
      <div>
        {
           objarry.map(o => {
             weekCount++
              return this.printRow(o, weekCount)
           })
            
        }
      </div>
      </div>
		)
	}
}
const ViewWeeklyHours =withStyles(styles, { withTheme: true })(WeeklyHours);
export default connect(mapStateToProps)(ViewWeeklyHours);
