import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

const shifts = [
  {
    start: 6,
    end: 16.5,
    display: "6am-430pm"
  },
  {
    start: 7,
    end: 17.5,
    display: "7am-530pm"
  },
  {
    start: 8,
    end: 18.5,
    display: "8am-630pm"
  },
  {
    start: 10,
    end: 20.5,
    display: "10am-830pm"
  },
  {
    start: 12,
    end: 22.5,
    display: "12pm-1030pm"
  },
  {
    start: 13,
    end: 23.5,
    display: "1pm-1130pm"
  },
  {
    start: 17,
    end: 30.5,
    display: "5pm-630am"
  },
  {
    start: 18,
    end: 30.5,
    display: "6pm-630am"
  },
  {
    start: 20,
    end: 30.5,
    display: "8pm-630am"
  }
]

class ShiftMaker extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      list: [],
      daysOff: [],
      time: "08:00",
      moment: null
    }
  }

  componentDidMount() {

    var time = moment(this.props.date.start).format("HH:mm");
    var obj = moment(this.props.date.start);
    if (time === "00:00"){
      time = "08:00";
      if (this.props.user.start_time)
        time = this.props.user.start_time
      var splitTime = time.split(/:/)
      obj.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1])).seconds(0).milliseconds(0);
    }
    //var day = moment();
    //var splitTime = time.split(/:/)
    //day.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1])).seconds(0).milliseconds(0);
    this.setState({time: time, moment: obj});
    this.setState({user: this.props.user});
    
    
    
  }
  
  componentDidUpdate(prevProps){
    if (!_.isEqual(prevProps.user, this.props.user)){
      this.setState({user: this.props.user});
      if (this.props.user){
        if (this.props.user.start_time){
          var time = this.props.user.start_time
          var day = moment();
          var splitTime = time.split(/:/)
          day.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1])).seconds(0).milliseconds(0);
          this.setState({ time : this.props.user.start_time, moment: day });
        }
      }
    }
  }

  pickshift = (shift) => {
    let theShift = {
      start: this.state.time,
      end: this.state.moment.add(shift, "hours").format("HH:mm")
    }
    this.props.pickShift(theShift)
  }


  handleChange = event => {
    var time = event.target.value
    var day = moment();
    var splitTime = time.split(/:/)
    day.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1])).seconds(0).milliseconds(0);
    this.setState({ time : event.target.value, moment: day });
  };

	render() {
    if (this.props.date === null || this.state.moment === null)
     return null

    const { classes } = this.props;
		return (
      <div>
        <h1 className={classes.h1}>Choose a Shift</h1>
          <div>
            <input type="time" value={this.state.time} name="time" step="900" onChange={this.handleChange} onSelect={this.handleChange} />
          </div>

          <div>
            <button onClick={() => this.pickshift(6.5)}>6 Hour Shift<br />{moment(this.state.moment).add(6.5, "hours").format("hh:mm A")}</button>
            <button onClick={() => this.pickshift(7.5)}>7 Hour Shift<br />{moment(this.state.moment).add(7.5, "hours").format("hh:mm A")}</button>
            <button onClick={() => this.pickshift(8.5)}>8 Hour Shift<br />{moment(this.state.moment).add(8.5, "hours").format("hh:mm A")}</button>
            <button onClick={() => this.pickshift(9.5)} >9 Hour Shift<br />{moment(this.state.moment).add(9.5, "hours").format("hh:mm A")}</button>
            <button onClick={() => this.pickshift(10.5)}>10 Hour Shift<br />{moment(this.state.moment).add(10.5, "hours").format("hh:mm A")}</button>
            <button onClick={() => this.pickshift(11.5)}>11 Hour Shift<br />{moment(this.state.moment).add(11.5, "hours").format("hh:mm A")}</button>
            <button onClick={() => this.pickshift(12.5)}>12 Hour Shift<br />{moment(this.state.moment).add(12.5, "hours").format("hh:mm A")}</button>
            <button onClick={() => this.pickshift(13.5)}>13 Hour Shift<br />{moment(this.state.moment).add(13.5, "hours").format("hh:mm A")}</button>
          </div>
      </div>
		)
	}
}

export default withStyles(styles, { withTheme: true })(ShiftMaker);
