import React from "react";
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router';

const styles = theme => ({
  listText: {
    fontSize: '.8rem',
    fontWeight: 'inherit',
    textTransform: 'uppercase',
    color: 'inherit',
    padding: '0 5px'
  },
  listItemIcon: {
    color: 'inherit'
  },
  active: {
    borderRight: '4px solid #205081',
    color: '#205081 !important',
    fontWeight: "900 !important"
  },
  listItem: {
    color: 'rgba(0,0,0,.66)',
    fontWeight: 400,
    fontFamily: "Roboto",
    display: "block",
    textDecoration: "none"
  }
});

class SideLink extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      active: ""
    };
  }

  componentWillMount(){
    this.props.history.listen(location => {
      if (location.pathname !== this.props.location.pathname) {
        this.props.location.pathname = location.pathname;
        this.forceUpdate();
      }
    });
  }

	render() {
    if (this.state === undefined)
      return null

		const { classes } = this.props;
		return (
      <>
      <NavLink className={classes.listItem} activeClassName={classes.active}  to={this.props.href}>
			<ListItem button key={this.props.text}>
				<ListItemIcon className={classes.listItemIcon}>{this.props.icon}</ListItemIcon>
				<ListItemText disableTypography className={classes.listText} primary={this.props.text} />
			</ListItem>
      </NavLink>
      </>
		)
	}
}


const SideLinkWithStyles = withStyles(styles, { withTheme: true })(SideLink);
export default withRouter(SideLinkWithStyles);
