import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

const shifts = [
  {
    start: 6,
    end: 16.5,
    display: "6am-430pm"
  },
  {
    start: 7,
    end: 17.5,
    display: "7am-530pm"
  },
  {
    start: 8,
    end: 18.5,
    display: "8am-630pm"
  },
  {
    start: 10,
    end: 20.5,
    display: "10am-830pm"
  },
  {
    start: 12,
    end: 22.5,
    display: "12pm-1030pm"
  },
  {
    start: 13,
    end: 23.5,
    display: "1pm-1130pm"
  },
  {
    start: 17,
    end: 30.5,
    display: "5pm-630am"
  },
  {
    start: 18,
    end: 30.5,
    display: "6pm-630am"
  },
  {
    start: 20,
    end: 30.5,
    display: "8pm-630am"
  }
]

class ShiftList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      list: [],
      daysOff: []
    }
  }

  componentDidMount() {
  }

  pickShift = (shift) => {
    this.props.pickShift(shift)
  }


  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

	render() {
    if (this.props.date === null)
     return null

    const { classes } = this.props;
		return (
      <div>
        <h1 className={classes.h1}>Choose a Shift</h1>
          <div>
            {shifts.map((shift) => {

              return(
                <button onClick={()=>this.pickShift(shift)}>{shift.display}</button>
              );
            })}
          </div>
      </div>
		)
	}
}

export default withStyles(styles, { withTheme: true })(ShiftList);
