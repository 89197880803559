import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { store } from "./stores/calendar.js";
import './index.css';
//import injectTapEventPlugin from 'react-tap-event-plugin';
import {
  BrowserRouter
} from 'react-router-dom'

import App from './App';
import 'react-notifications/lib/notifications.css';
import _ from 'underscore';


window._ = _;


const MainApplication = () => (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<MainApplication />, document.getElementById('root'));
