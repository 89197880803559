import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import Select from 'react-select'

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  }
});

class Customers extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      show: true, 
      note: "",
      start_time: "08:00",
      //shift_hours: null,
      unpublished: false
    }
  }

  componentDidMount() {
    this.getTech();
  }

  getTech = () => {
    let that = this;
    if (this.props.match.params.id) {
      axios({
        method: 'get',
        url: '/api/getUser',
        params: {
          id : this.props.match.params.id,
        }
      }).then(function (response) {
        console.log("get user:", response);
        if (response.data){
          that.setState({...response.data});
        }
        else {
          NotificationManager.error("Something Went Wrong", "ERROR", 5000);
        }
      });
    }
  }



  signup = (e) => {
    e.preventDefault();
    let url = "/auth/newuser";
    if (this.props.match.params.id)
      url = "/auth/edituser";
    let that = this;
    axios({
      method: 'post',
      url: url,
      data: {
        email: this.state.email,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        color: this.state.color,
        _id: this.state._id,
        show: this.state.show,
        note: this.state.note,
        unpublished: this.state.unpublished,
        start_time: this.state.start_time,
        //shift_hours: this.state.shift_hours
      }
    }).then(function (response) {
      console.log("user info:", response);
      if (response.data.message === "success"){
        if (that.props.match.params.id){
            NotificationManager.success(response.data.description, "USER EDITED", 5000);
            that.props.history.push("/users");
          }
        else
          NotificationManager.success(response.data.description, "USER CREATED", 5000);
        that.setState({
          first_name: "",
          last_name: "",
          email: "",
          color: "#000",
          note: "",
          show: true,
          start_time: "08:00",
          unpublished: false
        });
      }
      else {
        NotificationManager.error(response.data.description, "ERROR", 5000);
      }
    });
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };
  
  handleUnpublishedChange = event => {
    this.setState({ "unpublished" : event.value });
  }

handleSelectChange = event => {
  this.setState({ "show" : event.value });
}

	render() {
    const { list } = this.state;
    const { classes } = this.props;
    let show = {label: "Show", value: true}
    let unpublished = {label: "Can View Unpublished Schedules", value: true}
    if (!this.state.show)
      show = {label: "Hide", value: false}
      
    if (!this.state.unpublished)
      unpublished = {label: "Unpublished Schedules are hidden", value: false}
    
		return (
      <span>
        {!this.props.match.params.id ?
  			     <h1 className={classes.h1}>Add Tech</h1>
          :
            <h1 className={classes.h1}>Edit Tech</h1>
            }
          <form action="/auth/signup" method="POST">
           <input type="text" placeholder="First Name" name="first_name" value={this.state.first_name}
           onChange={this.handleChange}/>
         <input type="text" placeholder="Last Name" name="last_name" value={this.state.last_name}
         onChange={this.handleChange} />
           <input type="text" placeholder="email" name="email" value={this.state.email}
           onChange={this.handleChange} />
        <br />Note: <input type="text" placeholder="Note" name="note" value={this.state.note}
         onChange={this.handleChange}/>
         <br />Shift Start<input type="time" value={this.state.start_time} name="start_time" onChange={this.handleChange} onSelect={this.handleChange} />
          {/*<br />Normal Hours<input type="number" value={this.state.shift_hours} name="shift_hours" onChange={this.handleChange} onSelect={this.handleChange} />*/}
         <br />Color: <input className="color" type="color" placeholder="#000" name="color" value={this.state.color}
           onChange={this.handleChange} />
         <span className="name-example" style={{color: this.state.color}}>{this.state.first_name} {this.state.last_name}</span>
         <br />
         <Select className="select" placeholder="hide" name="show" onChange={this.handleSelectChange} value={show} options={[{label: "Hide", value: false}, {label: "Show", value: true}]} />
         <br />
         <Select className="select" placeholder="view unpublished" name="unpublished" onChange={this.handleUnpublishedChange} value={unpublished} options={[{label: "Can View Unpublished Schedules", value: true}, {label: "Unpublished Schedules are hidden", value: false}]} />
     <br /><button onClick={this.signup}>Submit</button>
          </form>
      </span>
		)
	}
}

export default withStyles(styles, { withTheme: true })(Customers);
