import React from "react";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import GroupIcon from '@material-ui/icons/Group';
import FaceIcon from '@material-ui/icons/Face';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import SideLink from './sideLink.js'
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  listText: {
    fontSize: '.8rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,.66)'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    /*width: theme.spacing.unit * 7 + 1,*/
    width: 58,
    [theme.breakpoints.up('sm')]: {
      width: 58,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
   toolbarSpacer: {
     minHeight: "92px"
   }
});

class Sidebar extends React.Component {

  state = {
    open: true
  };

	render() {
    const { classes } = this.props;



		return (
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: this.props.drawerOpen,
          [classes.drawerClose]: !this.props.drawerOpen,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: this.props.drawerOpen,
            [classes.drawerClose]: !this.props.drawerOpen,
          }),
        }}
        open={this.props.drawerOpen}
      >
        <div className={classes.toolbarSpacer} />
          <List>
            <SideLink text="My Schedule" icon={<PermContactCalendarIcon />} href="/me" />
            <SideLink text="Calendar" icon={<CalendarTodayIcon />} href="/calendar" />
            <SideLink text="Request Off" icon={<EventBusyIcon />} href="/requestOff" />
          </List>
            {
              this.props.user.role === "Admin" ?
                <List>
                  <Divider />
                <SideLink text="Users" icon={<FaceIcon />} href="/users" />
                <SideLink text="Calendar Edit" icon={<EventAvailableIcon />} href="/calendar/edit" />
                <SideLink text="Calendar Edit" icon={<FreeBreakfastIcon />} href="/holidays" />
                </List>
              : null
            }

          {/*
            <Divider />
            <List>
            <SideLink text="Data Analytics" icon={<AnalyticsIcon />} />
            <SideLink text="Ingest Status" icon={<StorageIcon />} />
            <SideLink text="File Status" icon={<FileIcon />} />
            <SideLink text="Data Purge" icon={<PurgeIcon />} />
            <SideLink text="SOI List" icon={<UploadIcon />} />
          </List>*/}
      </Drawer>
		)
	}
}

const SideBarWithStyles = withStyles(styles, { withTheme: true })(Sidebar);
export default connect(mapStateToProps)(SideBarWithStyles);
