import React,{Children} from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import AddCircle from '@material-ui/icons/AddCircle';
import { NavLink } from 'react-router-dom'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
 import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import _ from 'lodash';
import { connect } from 'react-redux';
import BootBox from '../_BootBox';
import TechList from '../shared/TechList';
import ShiftList from '../shared/ShiftList';
import ShiftMaker from '../shared/ShiftMaker';
import { Calendar as Picker, DateObject } from "react-multi-date-picker"
import '../../App.css';

const CustomMonthContainer = ({ currentUser, deleteEvent }) => props => {
    return <MonthEvent event={props} currentUser={currentUser} deleteEvent={deleteEvent}  />;
}

const CustomDayContainer = ({ currentUser, deleteEvent }) => props => {
    return <Event event={props} currentUser={currentUser} deleteEvent={deleteEvent} />;
}


const Event = React.memo((props) => {
  return (

    <span>
      <div>{props.event.event.description}</div>
      <strong>{props.event.event.title}</strong> <DeleteButton deleteEvent={props.deleteEvent} eventId={props.event.event._id} />

    </span>
  )
});

const MonthEvent = React.memo((props) => {
  return (

    <span style={{fontSize: "8pt"}} title={props.event.event.user_note}>
      {props.event.event.description}: {props.event.event.user_firstname}
    </span>
  )
});

const DeleteButton = React.memo((props) => {
    return (
      <button onClick={() => {props.deleteEvent(props.eventId)}}>Delete</button>
    )
});

function dateCheck(start, end, check) {
  let val = false;
  if (moment(start).isSame(moment(end).subtract(1, 'days'))){ //single day event
    if(moment(check).isSame(start)){
      val = true;
    }
  }
  else { //range
  if(moment(check).isBetween(start, end, null, '[]'))
    val = true;
  }
  return val;
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

const resourceMap = [
  { resourceId: 1, resourceTitle: 'EEG' },
  { resourceId: 2, resourceTitle: 'EMU' },
  { resourceId: 5, resourceTitle: 'ADULT EMU' },
  { resourceId: 3, resourceTitle: 'JHOC A' },
  { resourceId: 4, resourceTitle: 'JHOC B' },
]

const defaultShifts = [6,6,7,8,8,12,13,13,17,18,20,20];
const defaultWeekendShifts = [6,7,11,12,17,18,18,20];
const defaultHours = [10.5,10.5,10.5,10.5,10.5,10.5,10.5,10.5,12.5,12.5,10.5,10.5];
const defaultWeekendHours = [10.5,10.5,10.5,10.5,12.5,10.5,12.5,10.5];


const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

class EditCal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      addDayShow: false,
      pickShiftShow: false,
      publishShow: false,
      eventHolding: null,
      events: [],
      techHolding: null,
      highlighting: false,
      dummyStart: moment().startOf('month').startOf('week'),
      dummyEnd: moment().endOf('month').endOf('week'),
      publishDates: [],
      holidays: moment("01/25/2022").format("MM/DD/YYYY")
    }
  }

  componentDidMount() {
    this.nav(moment().startOf('month').startOf('week'), moment().endOf('month').endOf('week'));
    this.getHolidays();
  }
  
  getHolidays = () => {
    axios({
      method: 'get',
      url: "/api/getHolidays"
    }).then((response) => {
      if (response.data){
        console.log("holidays found:", response);
        let holidays = []
        _.each(response.data, t => {holidays.push(t.date)})
        this.setState({holidays: holidays})
      }
    
    });
  }
  
  publishToggle = () => {
    this.setState({publishShow: !this.state.publishShow})
  }
  
  publishTheseDates = (unpublish = true) => {
      let url = "/api/publish";
      let mess = "Published"
      if (!unpublish){
         mess = "Not Published Anymore" 
      }
      let dates = this.state.publishDates;
      _.each(dates, (d) => {
        d = moment(d);
      })
      console.log("the dates as objects", dates)
      axios({
        method: 'post',
        url: url,
        data: {dates: dates, show: unpublish}
      }).then((response) => {
        if (response.data === "Success")
          NotificationManager.success("Schedule "+mess, "HOORAY!", 5000);
        else
          NotificationManager.error("Something Went Wrong", "BOO!", 5000);
        console.log("schedule published:", response);
        this.publishToggle();
      });
  }

  createDummyShifts = () => {
    let url = "/api/addScheduleDay";
    let that = this;
    const { events } = this.state
    let newEvents = [];
    let array = [];
    let hrs = [];
    let start = 0;
    let end = 0;
    let dummyDate = moment();
    let description = "";
    let doFlag = true;
    let tmpEvent = {}
    for (var m = this.state.dummyStart; m.isBefore(this.state.dummyEnd); m.add(1, 'days')) {
      dummyDate = new moment(m);
      doFlag = true;
      _.each(events, function(v){
        if(doFlag && dummyDate.format("MM/DD/YYYY") === moment(v.start).format("MM/DD/YYYY")){
          doFlag = false; //don't do this date, shifts already exist
        }
      });
      console.log(m.format('YYYY-MM-DD'));
      if (doFlag) {

          if ((m.toDate().getDay() === 6) || (m.toDate().getDay() === 0)){ // defaultWeekendShifts
            array = defaultWeekendShifts;
            hrs = defaultWeekendHours;
          }
          else {
            array = defaultShifts;
            hrs = defaultHours;
          }
          for(let i = 0; i < array.length; i++){
            start = new moment(dummyDate).startOf("day").add(array[i], "hours");
            end = new moment(start).add(hrs[i], "hours");
            description = start.format("hh:mma") + "-" + end.format("hh:mma")
            if (start.format("MM/DD/YYYY") !== end.format("MM/DD/YYYY")){
              end = moment(start).endOf("day");
            }
            tmpEvent = {
              start: start.toDate(),
              resourceId: 1,
              end: end.toDate(),
              title: "available",
              description: description,
              color: "#999"
            }
            newEvents.push(tmpEvent);

          }
        }

    }
    console.log("allDone", newEvents);
    if(newEvents.length > 0){
      this.saveDummyDays(newEvents);
      this.setState({
        events: [
          ...this.state.events,
          ...newEvents
        ],
        dummyStart: moment().startOf('month').startOf('week')
      });
    }
  }

  getEvents = () => {
    fetch('/api/getSchedule')
    .then(res => res.json())
    //.then(list => console.log("res:", list))
    .then(list => {
      _.each(list, function(v){
        v.start = moment(v.start).toDate();
        v.end = moment(v.end).toDate();
      });
      this.setState({ events: list })
      console.log("this is the list", list);
    })
  }

  highLightTech = (id) => {
    console.log("techId to highlight:", id);
    let newList = this.state.events.filter(res => res.user_fk === id);
    this.setState({backup: this.state.events, events: newList, addDayShow: false, highlighting: true});
  }

  showAllTechs = () => {
    this.setState({events: this.state.backup, backup: null, highlighting: false})
  }

  highLightTechModal = () => {
    this.setState({addDayShow: true, eventHolding: null});
  }


  openModel = (event) => {
    if (this.state.highlighting)
      this.showAllTechs();
    this.setState({addDayShow: true, eventHolding: event});
  }

  closeModel = (event) => {
    this.setState({addDayShow: false, pickShiftShow: false, techHolding: null, eventHolding: null});
  }

  pickTech = (tech) => {
    console.log("THIS", tech);
    if (tech)
      this.confirmSelect(tech);
  }

  moveToShift = (tech, edit) => {
    this.setState({techHolding: tech, addDayShow: false, pickShiftShow: true, isEditing: edit});
  }

  pickShift = (shift) => {
    console.log("this is the shift picked:", shift, this.state.techHolding, this.state.eventHolding);
    let newStart, newEnd, resourceId;
    var startSplit =shift.start.split(/:/);
    var endSplit = shift.end.split(/:/);
    /*
    if (this.state.isEditing){
      newStart = new moment(this.state.eventHolding.start).startOf("day").add(shift.start, "hours");
      newEnd = new moment(this.state.eventHolding.start).startOf("day").add(shift.end, "hours");
      resourceId = this.state.eventHolding.resourceId;
    }
    else {
      newStart = moment(this.state.eventHolding.start).add(shift.start, "hours").toDate();
      newEnd =  moment(this.state.eventHolding.end).add(shift.end, "hours").toDate();
      resourceId = 1
    }
    */
    newStart = new moment(this.state.eventHolding.start).startOf("day").hours(parseInt(startSplit[0])).minutes(parseInt(startSplit[1])).seconds(0).milliseconds(0);
    newEnd = new moment(this.state.eventHolding.start).startOf("day").hours(parseInt(endSplit[0])).minutes(parseInt(endSplit[1])).seconds(0).milliseconds(0);
    /*if (parseInt(startSplit[0] > endSplit[0])){ // overnight shift
      newEnd.add(1, "days");
    }*/
    if (this.state.isEditing){
      resourceId = this.state.eventHolding.resourceId;
    } else  {
      resourceId = 1;
    }



    this.setState({
      eventHolding: {
          ...this.state.eventHolding,
          start: newStart,
          end: newEnd,
          resourceId: resourceId}
        }, () => this.confirmSelect(this.state.techHolding));
  }

  confirmSelect = (tech) => {
    let edit = this.state.isEditing;
    console.log("This is EDIT:", edit);
    let event = this.state.eventHolding;
    let { events } = this.state
    let makeCall = this.saveEvent
    if (edit) {
      makeCall = this.updateEvent
      console.log("this is the event to delete from state:", events, event);
      events = _.reject(events, function(el) { return el._id === event._id; });
      console.log("and this is after reject:", events);
      /*this.setState({
        events: newState,
      })*/
    }
    if (tech) {

    let newEvent = {};
    let resourceTitle = _.find(resourceMap, ['resourceId', event.resourceId]).resourceTitle;
    if (event.start === event.end){
      console.log("all day event");
    }
    else {
      console.log("event in resource:", event.resourceId)
    }
    if (moment(event.start) < moment(event.end)){
      newEvent = [{
        start: moment(event.start).toDate(),
        resourceId: event.resourceId,
        resourceTitle: resourceTitle,
        user_fk: tech._id,
        user_firstname: tech.first_name,
        user_lastname: tech.last_name,
        user_note: tech.note,
        end: moment(event.end).toDate(),
        title: tech.first_name + " " + tech.last_name,
        description: moment(event.start).format("hh:mma") + "-" + moment(event.end).format("hh:mma"),
        color: tech.color,
        requested: Date.now()
      }];
    }
    else {
      newEvent = [{
        start: moment(event.start).toDate(),
        resourceId: event.resourceId,
        resourceTitle: resourceTitle,
        user_fk: tech._id,
        user_firstname: tech.first_name,
        user_lastname: tech.last_name,
        user_note: tech.note,
        end: moment(event.start).endOf("day").toDate(),
        title: tech.first_name + " " + tech.last_name,
        description: moment(event.start).format("hh:mma") + "-" + moment(event.end).format("hh:mma"),
        color: tech.color,
        requested: Date.now()
      }];
    }
    /*
    add this to new Event array if you want night shift to wrap
    ,{
      start: moment(event.end).startOf("day").toDate(),
      resourceId: event.resourceId,
      resourceTitle: resourceTitle,
      user_fk: tech._id,
      user_firstname: tech.first_name,
      user_lastname: tech.last_name,
      end: event.end,
      title: tech.first_name + " " + tech.last_name,
      description: moment(event.end).startOf("day").format("hh:mma") + "-" + moment(event.end).format("hh:mma"),
      color: tech.color,
      requested: Date.now()
    }*/

    if (edit)
      newEvent[0]._id = event._id;

    console.log("this is the id:", newEvent[0]._id)

    this.setState({
      events: [
        ...events,
        ...newEvent
      ],
      eventHolding: null,
      techHolding: null,
      pickShiftShow: false,
      addDayShow: false,
      isEditing: false
    }, () => makeCall(newEvent));
    console.log("the new event:", newEvent);

}
}

nav = (start, end) => {

  this.setState({dummyStart: start, dummyEnd: end})
  let url = "/api/getMonthEventsEdit";
  let that = this;
  let mongostart = start.toDate();
  let mongoend = end.toDate();
  axios({
    method: 'post',
    url: url,
    data: {start: mongostart, end: mongoend}
  }).then(function (response) {
    console.log("new monthly events:", response);
    _.each(response.data, function(v){
      v.start = moment(v.start).toDate();
      v.end = moment(v.end).toDate();
    });
    that.setState({
      events: response.data
    });
  });
}

setValues = (values) => {
  let val = []
  _.each(values, (v,k) => {
    val[k] = moment(v).toDate()
  })
  this.setState({publishDates: val})
}



deleteEvent = (event) => {
    let url = "/api/deleteScheduleDay";
    let that = this;
    axios({
      method: 'post',
      url: url,
      data: {id: event}
    }).then(function (response) {
      console.log("schedule day info:", response);
      let newState = _.reject(that.state.events, function(el) { return el._id === event; });
      console.log("this is newState:", newState);
      that.setState({
        events: newState
      });
    });
}


/*
on move or resize, check and see what happens to the shift
it should display properly but sore to end at 11:59

I'm assuming since the event in the db "ends" at 11:59 ift doesn't know
how to handle a resize
*/
moveEvent = ({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot }) => {
  /*

  changing to only allow for moving to affect resources

  const { events } = this.state
  const idx = events.indexOf(event)
  let allDay = false;
  let description = moment(start).format("hh:mma") + "-" + moment(end).add(1, "minutes").format("hh:mma");
  if (!resourceId)
    resourceId = event.resourceId;

  if (moment(start).format("MM/DD/YYYY") !== moment(end).format("MM/DD/YYYY"))
      end = moment(start).endOf("day").toDate();


    let resourceTitle = _.find(resourceMap, ['resourceId', resourceId]).resourceTitle;
    const updatedEvent = { ...event, start, end, allDay, resourceId, resourceTitle, description }
    console.log("this is the updated:", updatedEvent);
    this.updateEvent(updatedEvent);
    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)
  */
  const { events } = this.state
  const idx = events.indexOf(event)

  if (!resourceId)
    resourceId = event.resourceId;

    let resourceTitle = _.find(resourceMap, ['resourceId', resourceId]).resourceTitle;
    const updatedEvent = { ...event, resourceId, resourceTitle }
    console.log("this is the updated:", updatedEvent);
    this.updateEvent(updatedEvent);
    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    this.setState({
      events: nextEvents,
    })

}

resizeEvent = ({ event, start, end, resourceId }) => {
  /*

  turning off resizing all together

    let resourceTitle = _.find(resourceMap, ['resourceId', resourceId]).resourceTitle;
    let description = moment(start).format("hh:mma") + "-" + moment(end).format("hh:mma");
    const { events } = this.state

    const idx = events.indexOf(event)
    let allDay = event.allDay

    const updatedEvent = { ...event, start, end, resourceId, resourceTitle, description }
    this.updateEvent(updatedEvent);
    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    this.setState({
      events: nextEvents,
    })
*/
  }

saveEvent = (event) => {
    let url = "/api/addScheduleDay";
    let that = this;
    _.forEach(event, function(e){
      console.log("the loop:", e)
      axios({
        method: 'post',
        url: url,
        data: {
          event: e
        }
      }).then(function (response) {
        console.log("saved schedule info:", response);
        const { events } = that.state
        const idx = events.indexOf(e)
        let _id = response.data._id;
        const updatedEvent = { ...e, _id }
        const nextEvents = [...events]
        nextEvents.splice(idx, 1, updatedEvent)

        that.setState({
          events: nextEvents,
        })

        if (response.data.message === "failure"){
          NotificationManager.error("Something Went Wrong", "ERROR", 5000);
        }
      });
    });
}

saveDummyDays = (events) => {
    let url = "/api/addDummyDays";
    let that = this;
      axios({
        method: 'post',
        url: url,
        data: {
          events
        }
      }).then(function (response) {
        console.log("saved schedule info:", response);

        that.setState({
          events: response.data.ops,
        })

        if (response.data.message === "failure"){
          NotificationManager.error("Something Went Wrong", "ERROR", 5000);
        }
      });
}

updateEvent = (event) => {
  console.log("this is the event to be updated: ", event)
  if (event.length){
    event = event[0];
  }
    let url = "/api/editScheduleDay";
    let that = this;
    axios({
      method: 'post',
      url: url,
      data: {
        event
      }
    }).then(function (response) {
      console.log("edited schedule info:", response);
      if (response.data.message !== "success")
        NotificationManager.error(response.data.message, "ERROR", 5000);
    });
}

	render() {
    const items = this.state.list;
    const { classes } = this.props;
		return (
      <div>
        <h1 className={classes.h1}>Calendar Edit</h1>
        <button onClick={() => this.publishToggle()}>Publish</button>
        {/*<button onClick={() => this.publishThisMonth(false)}>Unpublish</button>*/}
        <br /><button onClick={() => this.createDummyShifts()}>Create Shifts</button><br />
        { !this.state.highlighting ?
          <button onClick={() => this.highLightTechModal()}>Highlight Tech</button>
        :
          <button onClick={() => this.showAllTechs()}>Show everyone</button>
          }
        {/*
          resizable
          onEventResize={this.resizeEvent}

          add those to bring back resizing
        */}
        <DragAndDropCalendar
          selectable
          resizable={false}
          tooltipAccessor={null}
          startAccessor="start"
          endAccessor="end"
          localizer={localizer}
          views={{
            month: true,
            week: true,
            day: true
          }}
          onView={(view) => {
            this.setState({currentView: view});
            if (view === "day") {
              var header = document.getElementsByClassName("rbc-toolbar")[0];
              var sticky = header.offsetTop-100;
              window.onscroll = function() {
                
                  if (window.pageYOffset > sticky) {
                    header.classList.add("rbc-toolbar-fixed");
                    if(document.getElementsByClassName("rbc-time-header"))
                      document.getElementsByClassName("rbc-time-header")[0].classList.add("time-header-fixed");
                  } else {
                    header.classList.remove("rbc-toolbar-fixed");
                    if(document.getElementsByClassName("rbc-time-header"))
                      document.getElementsByClassName("rbc-time-header")[0].classList.remove("time-header-fixed");
                  }
                
              }
            }
            else {
              window.onscroll = function() {
                return false
            }
          }
          }}
          onNavigate={(date, view) => {
            let start, end;

            if (view === 'month') {
              start = moment(date).startOf('month').startOf('week');
              end = moment(date).endOf('month').endOf('week');
              this.nav(start, end);
            }

          }}
          events={this.state.events}
          style={{ height: 2400,width: '95%' }}
          defaultView={Views.MONTH}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          resources={resourceMap}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          onSelectSlot={this.openModel}
          onSelectEvent={this.openModel}
          onEventDrop={this.moveEvent}
          components={{
            dateCellWrapper: ({children, value}) => {
              return (
                React.cloneElement(Children.only(children), {
                  style: {
                    ...children.style,
                    backgroundColor: this.state.holidays.indexOf(moment(value).format("YYYY-MM-DD")) >= 0 ? '#ffe8e8' : '#fff'
                  },
                })
              );
            },
              month: {event: CustomMonthContainer({
                            currentUser: this.props.user._id,
                            deleteEvent: this.deleteEvent
                        })},
                        day: {event: CustomDayContainer({
                                      currentUser: this.props.user._id,
                                      deleteEvent: this.deleteEvent
                                  })},

            }}
            eventPropGetter={event =>
              { //const user = this.state.listUsers.find(user => user.id === event.user_id);
                const backgroundColor = event.color;
                return { style: { backgroundColor } };
              }}
              resizableAccessor={() => false}
        />

        <BootBox
          message="Choose the tech"
          component={<TechList pickTech={this.pickTech} highLightTech={this.highLightTech} moveToShift={this.moveToShift} date={this.state.eventHolding}/>}
          show={this.state.addDayShow}
          onYesClick={this.confirmSelect}
          onNoClick={this.closeModel}
          onClose={this.closeModel}
          cancelText="Cancel"
          confirmText="Add"
        />

        <BootBox
          message="Choose the Shift"
          component={<ShiftMaker user={this.state.techHolding} pickShift={this.pickShift} date={this.state.eventHolding}/>}
          show={this.state.pickShiftShow}
          onYesClick={this.confirmSelect}
          onNoClick={this.closeModel}
          onClose={this.closeModel}
          cancelText="Cancel"
          confirmText="Add"
        />
        
        <BootBox
          message="Publish which dates"
          component={<PublishOptions setValues={this.setValues} values={this.state.publishDates}/>}
          show={this.state.publishShow}
          onYesClick={() => this.publishTheseDates(true)}
          onNoClick={() => this.publishTheseDates(false)}
          onClose={this.publishToggle}
          cancelText="Unpublish"
          confirmText="Publish"
        />

        {/*<BootBox
          message="Choose the Shift"
          component={<ShiftMaker pickShift={this.pickShift} date={this.state.eventHolding}/>}
          show={this.state.addDayShow}
          onYesClick={this.confirmSelect}
          onNoClick={this.closeModel}
          onClose={this.closeModel}
          cancelText="Cancel"
          confirmText="Add"
        />
        */}
      </div>
		)
	}
}

class PublishOptions extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      values: [],
      currentMonth: moment().add(1, "M").startOf('month')
    }
  }

  componentDidMount() {
    this.setState({values: this.props.values})
  }
  
  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.values, this.props.values))
      this.setState({values: this.props.values})
  }
  
  changeMonth = (month) => {
    this.setState({currentMonth: month})
  }
  
  selectWholeMonth = () => {
    let days = [];
    let count = 1;
    let lastDate = this.state.currentMonth.endOf('month').format("DD");
    let day = this.state.currentMonth.startOf('month');
    while (count <= lastDate) {
      days.push(day.toDate())
      day = day.add(1, "d");
      count++;
    }
    this.setState({values: days})
  }

  render() {
    return (
      <div>
        <h1>Choose dates to publish</h1>
        <br />
      <Picker 
        multiple
        value={this.state.values} 
        currentDate={
          new DateObject({ 
            year: this.state.currentMonth.format("YYYY"),
            month: this.state.currentMonth.format("MM"),
            day: 1
          })
        }
        onChange={array => { //Array of Dateobjecs
          this.props.setValues(array.toString().split(","))
        }}
        onMonthChange={month => {
          this.changeMonth(moment(month.toString()))
        }}
      />
      <span className="selectAll" onClick={this.selectWholeMonth}>Select Whole Month</span>
      </div>
    )
  }
}


//this can be a calendar prop
//onSelectEvent={event => alert(event.title)}
const EditCalendar = withStyles(styles, { withTheme: true })(EditCal);
export default connect(mapStateToProps)(EditCalendar);
