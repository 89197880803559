import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import AddCircle from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { NavLink } from 'react-router-dom'
import moment from 'moment';

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

class HolidayList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      list: []
    }
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    fetch('/api/getHolidays')
    .then(res => res.json())
    .then(list => this.setState({ list }))
  }
  
  deleteHoliday = (id) => {
    let url = "/api/deleteHoliday";
    axios({
      method: 'post',
      url: url,
      data: {
        id: id
    }}).then((response) => {
      if (response.data){
        this.getList()
      }
      else {
        NotificationManager.error(response.data.description, "ERROR", 5000);
      }
    });
  }


  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

	render() {
    const items = this.state.list;
    const { classes } = this.props;
		return (
      <div>
        <h1 className={classes.h1}>Holidays <NavLink className={classes.h1icon} to="/holiday/add"><AddCircle /></NavLink></h1>
        {items.length ? (
          <div>
            {items.map((item) => {
              return(
                <div>
                  <span>{moment(item.date).format("MM/DD/YYYY")} {item.name} <DeleteIcon onClick={() => this.deleteHoliday(item._id)} /></span>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <h2>No Holidays Found</h2>
          </div>
        )
      }
      </div>
		)
	}
}

export default withStyles(styles, { withTheme: true })(HolidayList);
