import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const styles = theme => ({
  typography: {
    padding: theme.spacing.unit * 2,
    width: 350
  },
  avatar: {
    margin: 10,
  }
});

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};


class UserBubble extends React.Component {

  state = {
		anchorEl: null
  };

	handleMenu = event => {
    this.setState({ anchorEl: null });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    axios({
      method: 'get',
      url: '/auth/logout',
    }).then(function (response) {
      console.log("logged out!", response);
      document.location='/login';
    });
  }

	render() {
		const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    if (!this.props.user.email)
      return null

		return (
      <ClickAwayListener onClickAway={this.handleClose} >
        <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <Avatar className={classes.avatar}>{this.props.user.first_name.charAt(0)}{this.props.user.last_name.charAt(0)}</Avatar>
                </IconButton>

                  <Popper id="menu-appbar" open={open} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <button onClick={this.logout}>Log Out</button>
                        <Link to={'/changePassword'}>Change Password</Link>
                        <span>{this.props.user.first_name+" "+this.props.user.last_name}</span>
                      </Paper>
                    </Fade>
                  )}
                  </Popper>
          </div>
        </ClickAwayListener>
		)
	}
}


const Bubble =  withStyles(styles, { withTheme: true })(UserBubble);
const ConnectedBubble = connect(mapStateToProps)(Bubble);
export default withRouter(ConnectedBubble);
