import React, { Component } from 'react';
import {
  Route,
  Switch,
  withRouter,
  Redirect
} from 'react-router-dom'
import {store} from './stores/calendar';
import Home from './home';
import Login from './components/login/login';
import NewUser from './components/users/addUser';
import UserList from './components/users/UserList';
import Default from './components/Default';
import Password from './components/login/password';
import CalendarView from './components/calendar/View';
import CalendarEdit from './components/calendar/Edit';
import DaysOff from './components/calendar/DaysOff';
import Me from './components/calendar/Me'
import HolidayList from "./components/holidays/HolidayList";
import HolidayAdd from "./components/holidays/AddHoliday";
import WeeklyHours from "./components/report/WeeklyHours";

class Routes extends Component {

  render() {
    return (
      <Switch>
        <UnAuthenticatedRoute exact path='/' component={Login}/>
        <AuthenticatedRoute path="/default" component={Default} />
        <UnAuthenticatedRoute path='/login' component={Login} />
        <AuthenticatedRoute path='/user/add' component={NewUser} roles={['Admin']} />
        <AuthenticatedRoute path='/report' component={WeeklyHours} roles={['Admin']} />
        <AuthenticatedRoute path='/me' component={Me} roles={['Admin', 'Tech']} />
        <AuthenticatedRoute path='/user/:id' component={NewUser} roles={['Admin']} />
        <AuthenticatedRoute path='/users' component={UserList} roles={['Admin']} />
          <AuthenticatedRoute path='/holiday/add' component={HolidayAdd} roles={['Admin']} />
          <AuthenticatedRoute path='/holidays' component={HolidayList} roles={['Admin']} />
        <AuthenticatedRoute path='/calendar/edit' component={CalendarEdit} roles={['Admin']} />
        <AuthenticatedRoute path='/admin' component={UserList} roles={['Admin']} />
        <AuthenticatedRoute path='/changePassword' component={Password}  />
        <AuthenticatedRoute path='/calendar' component={CalendarView} roles={['Admin', 'Tech']} />
        <AuthenticatedRoute path='/requestOff' component={DaysOff} roles={['Admin', 'Tech']} />
      </Switch>
    )
  }
}




class UnAuthenticatedRoute extends Component {
  render() {
    const reduxstate = store.getState();
    if (!reduxstate.auth.isAuthenticated)
      return <Route path={this.props.path} component={this.props.component} />;
    else
      return <Redirect to="/default" />;
}
}

class AuthenticatedRoute extends Component {
  render() {
    const reduxstate = store.getState();
    if (reduxstate.auth.isAuthenticated) {
      let userRole = reduxstate.auth.user.role;
      console.log("in the route:", userRole, this.props.roles);
      if (this.props.incompleteProfile === true) // no further vetting needed
        return <Route path={this.props.path} component={this.props.component} />
     if (this.props.roles !== undefined) { // role based route
       if (this.props.roles.includes(userRole))
        return <Route path={this.props.path} component={this.props.component} />
      else
        return <Redirect to="/permissionsError" />
     }
     else
        return <Route path={this.props.path} component={this.props.component} />
        }
        else
          return <Redirect to="/login" />

  }
}

export default withRouter(Routes)
