import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user,
    logged_in: state.auth.isAuthenticated
  };
};

class ConnectedDefault extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };

  }

  componentDidMount() {
    console.log("user info:", this.props.user);
      if (this.props.user.role === "Tech"){
        this.props.history.push("/me");
      }
      else if (this.props.user.role === "Admin"){
        this.props.history.push("/admin");
      }
  }

  render() {
    if (this.props == null) {
        return null
      }

    return (
        <div className="side-page">
          <h2>Redirect</h2>
          This page should push you to where you are supposed to go
      </div>
    )
  }

}

const Default = connect(mapStateToProps)(ConnectedDefault);
export default withRouter(Default);
