import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

function dateCheck(start, end, check) {
  let val = false;
  if (moment(start).isSame(moment(end).subtract(1, 'days'))){ //single day event
    if(moment(check).isSame(start)){
      val = true;
    }
  }
  else { //range
  if(moment(check).isBetween(start, end, null, '[]'))
    val = true;
  }
  return val;
}

class TechList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      list: [],
      daysOff: []
    }
  }

  componentDidMount() {
    this.getList();
    this.getTimeOff();
  }

  getList = () => {
    fetch('/api/users')
    .then(res => res.json())
    .then(list => this.setState({ list }))
  }

  getTimeOff = () => {
    fetch('/api/getDaysOff')
    .then(res => res.json())
    .then(list => this.setState({daysOff: list}))
  }

  pickTech = (item) => {
    console.log("this is the item:", item);
    console.log("this is props:", this.props.date);
    if (this.props.date === null){
      this.props.highLightTech(item._id);
    } else {
    if (this.props.date.slots === undefined){ // editing from month
      this.props.moveToShift(item, true)
    }
    else if (this.props.date.slots.length === 1) { //from month
      this.props.moveToShift(item, false)
    }
    else  {
    this.props.pickTech(item)
    }
    }
  }


  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

	render() {

    const items = this.state.list;
    const { classes } = this.props;
    let date = this.props.date;
    let show = false;
		return (
      <div>
        <h1 className={classes.h1}>Choose a Tech</h1>
        {items.length ? (
          <div>
            {items.map((item) => {
              show = false;
              if (date !== null){
                _.forEach(this.state.daysOff, function(d){
                  if (!show){
                  show = dateCheck(d.start, d.end, date.start) && d.user_fk === item._id;
                  }
                });
              }
              return(
                !show ?
                <div>
                  <span style={{color: item.color}}><a onClick={()=>this.pickTech(item)} className="list-links" style={{color: item.color}}>{item.first_name} {item.last_name}</a></span>
                </div>
                :
                <div>
                  <span style={{color: "#ccc"}}><a className="list-links off" style={{color: "#ccc"}}>{item.first_name} {item.last_name} requested off</a></span>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <h2>No Users Found</h2>
          </div>
        )
      }
      </div>
		)
	}
}

export default withStyles(styles, { withTheme: true })(TechList);
