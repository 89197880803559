import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import AddCircle from '@material-ui/icons/AddCircle';
import { NavLink } from 'react-router-dom'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
 import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import _ from 'lodash';
import { connect } from 'react-redux';

const CustomMonthContainer = ({ currentUser, deleteEvent }) => props => {
    return <MonthEvent event={props} currentUser={currentUser} deleteEvent={deleteEvent} />;
}

const CustomDayContainer = ({ currentUser, deleteEvent }) => props => {
    return <Event event={props} currentUser={currentUser} deleteEvent={deleteEvent} />;
}

const Event = React.memo((props) => {
  return (

    <span>
      <strong>{props.event.event.title}</strong>

    </span>
  )
});

const MonthEvent = React.memo((props) => {
  return (

    <span style={{fontSize: "8pt"}}>
      {props.event.event.description}: {props.event.event.user_firstname}
    </span>
  )
});


const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

const resourceMap = [
  { resourceId: 1, resourceTitle: 'EEG' },
  { resourceId: 2, resourceTitle: 'EMU' },
  { resourceId: 5, resourceTitle: 'ADULT EMU' },
  { resourceId: 3, resourceTitle: 'JHOC A' },
  { resourceId: 4, resourceTitle: 'JHOC B' },
]

const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

class ViewCal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      events: [],
      resource: 0
    }
  }

  componentDidMount() {
    //this.getEvents();
    this.nav(moment().startOf('month').startOf('week'), moment().endOf('month').endOf('week'));
  }

  nav = (start, end) => {

    this.setState({dummyStart: start, dummyEnd: end})
    let url = "/api/getMonthEvents";
    let that = this;
    let mongostart = start.toDate();
    let mongoend = end.toDate();
    axios({
      method: 'post',
      url: url,
      data: {start: mongostart, end: mongoend, unpublished: this.props.user.unpublished}
    }).then(function (response) {
      console.log("new monthly events:", response);
      _.each(response.data, function(v){
        v.start = moment(v.start).toDate();
        v.end = moment(v.end).toDate();
      });
      that.setState({
        events: response.data
      });
    });
  }

  getEvents = () => {
    let that = this;
    let mongostart = this.state.dummyStart.toDate();
    let mongoend = this.state.dummyEnd.toDate();
    let id = this.state.resource;
    let url = "/api/getScheduleByResource";


     axios({
       method: 'post',
       url: url,
       data: {start: mongostart, end: mongoend, id:id, unpublished: this.props.user.unpublished}
     }).then(function (response) {
       console.log("response.data", response.data)
       _.each(response.data, function(v){
         v.start = moment(v.start).toDate();
         v.end = moment(v.end).toDate();
       });
       that.setState({
         events: _.reject(response.data, function(el) {
           //console.log("this is el", el.title);
           return el.user_firstname === undefined })
       });
     });
      /*
    fetch('/api/getSchedule')
    .then(res => res.json())
    //.then(list => console.log("res:", list))
    .then(list => {
      _.each(list, function(v){
        v.start = moment(v.start).toDate();
        v.end = moment(v.end).toDate();
      });
      this.setState({ events: list })
      console.log("this is the list", list);
    })*/

  }

  switchView = (id) => {
    this.setState({resource: id}, () => this.getEvents())
  }

	render() {
    const items = this.state.list;
    const { classes } = this.props;
    let id = ""
		return (
      <div>
        <h1 className={classes.h1}>{
            _.find(resourceMap, ['resourceId', this.state.resource]) ?
            _.find(resourceMap, ['resourceId', this.state.resource]).resourceTitle : "Everywhere"
          }   Calendar</h1>
        <h4>
          <span onClick={() => this.switchView(0)}>Everywhere</span>
          {resourceMap.map((r) => {
            return(
              <span className="spaceout" onClick={() => this.switchView(r.resourceId)}>{r.resourceTitle}</span>
            )
        })}
      </h4>

        <DragAndDropCalendar
          startAccessor="start"
          endAccessor="end"
          localizer={localizer}
          views={{
            month: true,
            week: true,
            day: true
          }}
          onNavigate={(date, view) => {
            let start, end;

            if (view === 'month') {
              start = moment(date).startOf('month').startOf('week');
              end = moment(date).endOf('month').endOf('week');
              this.nav(start, end);
            }

          }}
          onView={(view) => {
            if (view === "day") {
              var header = document.getElementsByClassName("rbc-toolbar")[0];
              var sticky = header.offsetTop-100;
              window.onscroll = function() {
                
                  if (window.pageYOffset > sticky) {
                    header.classList.add("rbc-toolbar-fixed");
                    if(document.getElementsByClassName("rbc-time-header"))
                      document.getElementsByClassName("rbc-time-header")[0].classList.add("time-header-fixed");
                  } else {
                    header.classList.remove("rbc-toolbar-fixed");
                    if(document.getElementsByClassName("rbc-time-header"))
                      document.getElementsByClassName("rbc-time-header")[0].classList.remove("time-header-fixed");
                  }
                
              }
            }
          }}
          events={this.state.events}
          style={{ height: 2400,width: '95%' }}
          defaultView={Views.MONTH}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          resources={resourceMap}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          components={{
              month: {event: CustomMonthContainer({
                            currentUser: this.props.user._id,
                            deleteEvent: this.deleteEvent
                        })},
                        day: {event: CustomDayContainer({
                                      currentUser: this.props.user._id,
                                      deleteEvent: this.deleteEvent
                                  })}

            }}
            eventPropGetter={event =>
              { //const user = this.state.listUsers.find(user => user.id === event.user_id);
                const backgroundColor = event.color;
                return { style: { backgroundColor } };
              }}
        />


      </div>
		)
	}
}
//this can be a calendar prop
//onSelectEvent={event => alert(event.title)}
const ViewCalendar = withStyles(styles, { withTheme: true })(ViewCal);
export default connect(mapStateToProps)(ViewCalendar);
