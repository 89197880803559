import { createSlice } from "@reduxjs/toolkit"
import isEmpty from '../_isEmpty';

export const auth = createSlice({
  name: "auth",
  initialState: {
      isAuthenticated : false,
      isMasquerade : false,
      user : {},
      masquerade_user : null,
  },
  reducers: {
    setUser: (state, action) => {
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.user,
        isMasquerade : action.payload.is_masquerade,
        masquerade_user : action.payload.admin_id
      }
    },

    setMasquerade: (state, action) => {
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        isMasquerade : true,
        user: action.payload.user,
        masquerade_user : action.payload.admin_id
      }
    },

    endMasquerade: (state, action) => {
      return {
        ...state,
        isMasquerade : false,
        masquerade_user : null,
      }
    }

  }
})

// Extract the action creators object and the reducer
const { actions, reducer } = auth
// Extract and export each action creator by name
export const { setUser, setMasquerade, endMasquerade } = actions
// Export the reducer, either as a default or named export
export default reducer
