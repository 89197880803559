import React from "react";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import axios from 'axios'

import {NotificationManager} from 'react-notifications';
const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  input: {
    display: "block"
  }
});

class Login extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      email: "",
      password: ""
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

  componentDidMount() {
  }

  login = (e) => {
    e.preventDefault();
    let that = this;
    axios({
      method: 'post',
      url: '/auth/login',
      data: {
        email: this.state.email,
        password: this.state.password
      }
    }).then(function (response) {
      console.log("user info:", response.data.user);
      if (response.data.message === "success"){
        document.location = "/default";
      }
      else {
        NotificationManager.error("Wrong Name/PW", 'Unsuccessful Login', 5000);
      }
    });
  }

	render() {
    const { list } = this.state;
    const { classes } = this.props;
		return (
      <span>
  			<h1 className={classes.h1}>Log In</h1>
          <form onSubmit={this.login}>
            <TextField
              className="inputs"
              label="Email"
              placeholder="Email"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          <br />
            <TextField
              className="inputs"
              label="Password"
              placeholder="Password"
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />

            <br />
            <Button type="submit" onClick={this.login} variant="contained" color="primary" className="btn_action">
              Login
            </Button>
          </form>
      </span>
		)
	}
}

export default withStyles(styles, { withTheme: true })(Login);
