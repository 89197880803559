import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import Select from 'react-select'

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200 
  }
});

class Holidays extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      date: "",
      title: ""
    }
  }

  componentDidMount() {
    //this.getTech();
  }

  // getTech = () => {
  //   let that = this;
  //   if (this.props.match.params.id) {
  //     axios({
  //       method: 'get',
  //       url: '/api/getUser',
  //       params: {
  //         id : this.props.match.params.id,
  //       }
  //     }).then(function (response) {
  //       console.log("get user:", response);
  //       if (response.data){
  //         that.setState({...response.data});
  //       }
  //       else {
  //         NotificationManager.error("Something Went Wrong", "ERROR", 5000);
  //       }
  //     });
  //   }
  // }



  addHoliday = (e) => {
    e.preventDefault();
    let url = "/api/addHoliday";
    axios({
      method: 'post',
      url: url,
      data: {
        date: this.state.date,
        name: this.state.name
    }}).then((response) => {
      if (response.data.message === "success"){
        if (this.props.match.params.id){
            NotificationManager.success(response.data.description, "USER EDITED", 5000);
            this.props.history.push("/holidays");
          }
        else
          NotificationManager.success(response.data.description, "USER CREATED", 5000);
        this.setState({
          name: "",
          date: "",
        });
      }
      else {
        NotificationManager.error(response.data.description, "ERROR", 5000);
      }
    });
  }

  handleChange = event => {
    //console.log("event.target.value",event.target.value )
    this.setState({ [event.target.name] : event.target.value });
  };
   

	render() {
    const { classes } = this.props; 
		return (
      <span>
        {!this.props.match.params.id ?
  			     <h1 className={classes.h1}>Add Holiday</h1>
          :
            <h1 className={classes.h1}>Edit Tech</h1>
            }
          <form>
           <input type="text" placeholder="Name" name="name" value={this.state.name}
           onChange={this.handleChange}/>
           <input type="date" name="date" value={this.state.date} onChange={this.handleChange} />
     <br /><button onClick={this.addHoliday}>Submit</button>
          </form>
      </span>
		)
	}
}

export default withStyles(styles, { withTheme: true })(Holidays);
