import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import AddCircle from '@material-ui/icons/AddCircle';
import { NavLink } from 'react-router-dom'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import _ from 'lodash';
import { connect } from 'react-redux';

const CustomEventContainer = ({ currentUser, deleteEvent }) => props => {
    return <Event event={props} currentUser={currentUser} deleteEvent={deleteEvent} />;
}

const Event = React.memo((props) => {
  return (

    <span>
      <strong>{props.event.event.title}</strong> {props.currentUser === props.event.event.user_fk ? <DeleteButton deleteEvent={props.deleteEvent} eventId={props.event.event._id} /> : null}
      <br />{props.event.event.description !== "a" ? props.event.event.description : "PTO"}
    </span>
  )
});

const DeleteButton = React.memo((props) => {
    return (
      <button onClick={() => {props.deleteEvent(props.eventId)}}>Delete</button>
    )
});

function dateCheck(start, end, check) {
  let val = false;
  if (moment(start).isSame(moment(end).subtract(1, 'days'))){ //single day event
    if(moment(check).isSame(start)){
      val = true;
    }
  }
  else { //range
  if(moment(check).isBetween(start, end, null, '[]'))
    val = true;
  }
  return val;
}

const mapStateToProps = (state, props) => {
  return {
    user: state.auth.user
  };
};

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

const localizer = momentLocalizer(moment)

class DaysOff extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      events: []
    }
  }

  componentDidMount() {
    this.getEvents();
  }

  getEvents = () => {
    fetch('/api/getDaysOff')
    .then(res => res.json())
    //.then(list => console.log("res:", list))
    .then(list => this.setState({ events: list }))
  }

  handleSelect = ({ start, end }) => {
  let eventcount = 0;
  console.log("test", start === end);
  let checkDates = moment(start);
  let loopend = moment(start);
  let daycount = [];
  let dayCountnum = 0;
  let tmp = null;
  console.log("hmm", loopend, start, end);
  if (start !== end){
    while (!moment(end).add(1, 'days').isSame(moment(loopend._d))){
      console.log("loop count", loopend._d);
      daycount[dayCountnum] = 0;
      this.state.events.forEach(function(k){
        if (dateCheck(k.start, k.end, loopend._d))
          daycount[dayCountnum]++;
      });
      loopend.add(1, 'days');
      dayCountnum++;
    }
    console.log("a range was selected:", daycount);
    if (daycount.includes(2)){
      console.log("Sorry, you can't add this")
      eventcount = 2;
    }
  }
  else {
  this.state.events.forEach(function(k){
    if (dateCheck(k.start, k.end, checkDates))
      eventcount++;
  });
  }

  if (eventcount <= 1){
  const title = window.prompt('PTO description');
  let theend = moment(end, "DD-MM-YYYY").add(10, 'hours')._d;
  let newEvent = null;
  if (title){
    newEvent = {
      start: start,
      user_fk: this.props.user._id,
      end: theend,
      title: this.props.user.first_name + " " + this.props.user.last_name,
      description: title,
      color: this.props.user.color,
      requested: Date.now()
    };
    this.setState({
      events: [
        ...this.state.events,
        newEvent
      ],
    })
    this.saveEvent(newEvent);
  }
  }
  else {
    alert("more than 2");
  }
}

deleteEvent = (event) => {
    let url = "/api/deleteDayOff";
    let that = this;
    console.log("delete clicked:", event)
    axios({
      method: 'post',
      url: url,
      data: {id: event}
    }).then(function (response) {
      console.log("saved day off info:", response);
      let newState = _.reject(that.state.events, function(el) { return el._id === event; });
      console.log("this is newState:", newState);
      that.setState({
        events: newState
      });
      /*if (response.data.message === "success"){
        if (that.props.match.params.id){
            NotificationManager.success(response.data.description, "USER EDITED", 5000);
            that.props.history.push("/users");
          }
        else
          NotificationManager.success(response.data.description, "USER CREATED", 5000);
        that.setState({
          first_name: "",
          last_name: "",
          email: "",
          color: "#000"
        });
      }
      else {
        NotificationManager.error(response.data.description, "ERROR", 5000);
      }*/
    });
}

saveEvent = (event) => {
    let url = "/api/addDayOff";
    let that = this;
    axios({
      method: 'post',
      url: url,
      data: {
        event
      }
    }).then(function (response) {
      console.log("saved day off info:", response);
      /*if (response.data.message === "success"){
        if (that.props.match.params.id){
            NotificationManager.success(response.data.description, "USER EDITED", 5000);
            that.props.history.push("/users");
          }
        else
          NotificationManager.success(response.data.description, "USER CREATED", 5000);
        that.setState({
          first_name: "",
          last_name: "",
          email: "",
          color: "#000"
        });
      }
      else {
        NotificationManager.error(response.data.description, "ERROR", 5000);
      }*/
    });
}

	render() {
    const items = this.state.list;
    const { classes } = this.props;
		return (
      <div>
        <h1 className={classes.h1}>Days Off</h1>

        <Calendar
          selectable
          localizer={localizer}
          views={{
            month: true,
            week: true,
            day: true
          }}
          events={this.state.events}
          style={{ height: 1200,width: '95%' }}
          defaultView={Views.Month}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}

          onSelectSlot={this.handleSelect}
          components={{event: CustomEventContainer({
                        currentUser: this.props.user._id,
                        deleteEvent: this.deleteEvent
                    })}}
          eventPropGetter={event =>
            { //const user = this.state.listUsers.find(user => user.id === event.user_id);
              const backgroundColor = event.color;
              return { style: { backgroundColor } };
            }}
        />
      </div>
		)
	}
}
//this can be a calendar prop
//onSelectEvent={event => alert(event.title)}
const DaysOffWithStyles = withStyles(styles, { withTheme: true })(DaysOff);
export default connect(mapStateToProps)(DaysOffWithStyles);
