import React from "react";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import AddCircle from '@material-ui/icons/AddCircle';
import { NavLink } from 'react-router-dom'

const styles = theme => ({
  h1: {
    color: '#0277bd',
    fontSize: '48px',
    fontWeight: 200,
    margin: 0
  },
  icon: {
    fontSize: '48px',
    fontWeight: 200
  },
  h1icon: {
    color: 'inherit',
    padding: '0 5px'
  },
});

class UserList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      list: []
    }
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    fetch('/api/allusers')
    .then(res => res.json())
    .then(list => this.setState({ list }))
  }


  handleChange = event => {
    this.setState({ [event.target.name] : event.target.value });
  };

	render() {
    const items = this.state.list;
    const { classes } = this.props;
		return (
      <div>
        <h1 className={classes.h1}>Techs <NavLink className={classes.h1icon} to="/user/add"><AddCircle /></NavLink></h1>
        {items.length ? (
          <div>
            {items.map((item) => {
              return(
                <div>
                  <span style={{color: item.color}}><Link className="list-links" style={{color: item.color}} to={'/user/' + item._id}>{item.first_name} {item.last_name}</Link></span>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <h2>No Users Found</h2>
          </div>
        )
      }
      </div>
		)
	}
}

export default withStyles(styles, { withTheme: true })(UserList);
