import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux'
import { auth, filter } from "../ducks";

const rootReducer = combineReducers({
  auth: auth.reducer
});

export const store = configureStore({
  reducer: rootReducer
});
