import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Logo from './logo';
import UserBubble from './userBubble';
/*import Button from '@material-ui/core/Button';*/

const styles = theme => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
	avatar: {
    margin: 10,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 12,
  },
	button: {
		fontWeight: 800
	},
	appBar: {
    zIndex: theme.zIndex.drawer + 1,
  }
});

class Header extends React.Component {

	state = {
    open: true
  };

	render() {
		const { classes } = this.props;
		return (
			<AppBar
				position="fixed"
				 className={classes.appBar}
			>
				<Toolbar>
					<Toolbar className={classes.grow}>


					<IconButton
						color="inherit"
						aria-label="Toggle Drawer"
						onClick={this.props.handleToggleDrawer}
						className={classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
          EEG Work Calendar
					</Toolbar>
					{/*<Button className={classes.button} color="inherit">Home</Button>
					<Button className={classes.button} color="inherit">Access</Button>
					<Button className={classes.button} color="inherit">Training</Button>
					<Button className={classes.button} color="inherit">Contact</Button>
          */}
					<UserBubble />
				</Toolbar>

			</AppBar>
		)
	}
}

export default withStyles(styles, { withTheme: true })(Header);
